exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-js": () => import("./../../../src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-archived-algo-js": () => import("./../../../src/pages/archived/algo.js" /* webpackChunkName: "component---src-pages-archived-algo-js" */),
  "component---src-pages-archived-trading-rules-standard-js": () => import("./../../../src/pages/archived/trading-rules/standard.js" /* webpackChunkName: "component---src-pages-archived-trading-rules-standard-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-trading-rules-fp-evaluation-js": () => import("./../../../src/pages/trading-rules/fp-evaluation.js" /* webpackChunkName: "component---src-pages-trading-rules-fp-evaluation-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

